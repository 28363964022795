/* eslint-disable @typescript-eslint/ban-types */
import { restaurantResponseParse } from '@/utils/restaurant';
import { supplierResponseParse } from '@/utils/supplier';

const orderProductParse = (product: {
  allergens: [];
  available?: boolean;
  brand: string;
  code?: string;
  created_at: string;
  created_by_restaurant?: number;
  currency_code: string;
  formatted_price: Product['formatted_price'];
  id: number;
  name: string;
  order_cutoff_times: {};
  picture_url?: string;
  presentation?: string;
  price: string;
  product_category_id: number;
  product_category: any;
  product_thumbnail: {
    original_url: string;
    medium_url: string;
    thumb_url: string;
  };
  replacement_products?: string;
  seasonality_end_date?: string;
  seasonality_start_date?: string;
  sku: string;
  stock?: number;
  supplier_id: number;
  unit_name: string;
  unit: string;
  updated_at: string;
  uploaded_by_csv_at?: string;
}) => ({
  allergens: [],
  available: product.available,
  brand: product.brand,
  code: product.code,
  created_by_restaurant: product.created_by_restaurant,
  createdAt: product.created_at,
  currencyCode: product.currency_code,
  formatted_price: product.formatted_price,
  id: product.id,
  name: product.name,
  orderCutoffTimes: product.order_cutoff_times,
  pictureUrl: product.picture_url,
  presentation: product.presentation,
  price: product.price,
  product_category: product.product_category,
  product_thumbnail: product.product_thumbnail,
  productCategoryId: product.product_category_id,
  productCategoryName: product.product_category?.name,
  replacementProducts: product.replacement_products,
  seasonalityEndDate: product.seasonality_end_date,
  seasonalityStartDate: product.seasonality_start_date,
  sku: product.sku,
  stock: product.stock,
  supplierId: product.supplier_id,
  unit: product.unit,
  unitName: product.unit_name,
  updatedAt: product.updated_at,
  uploadedByCsvAt: product.uploaded_by_csv_at,
});

export const orderLinesParse = (
  orderLines: Array<{
    created_at: string;
    id: number;
    order_id: number;
    product?: Product;
    product_id: number;
    quantity: number;
    updated_at: string;
  }>,
) => {
  return (orderLines || []).map(line => ({
    createdAt: line.created_at,
    id: line.id,
    orderId: line.order_id,
    product: line.product ? orderProductParse(line.product) : undefined,
    productId: line.product_id,
    quantity: line.quantity,
    updatedAt: line.updated_at,
  }));
};

export const getOrdersResponseParse = (response: BDGetOrder[]): Order[] => {
  // @ts-ignore
  return (response || []).map(order => ({
    createdAt: order.created_at,
    deliveryDate: order.delivery_date,
    deliveryInstructions: order.delivery_instructions,
    id: order.id,
    newDeliveryAddress: order.new_delivery_address,
    orderEvents: order.order_events,
    orderLines: orderLinesParse(order.order_lines),
    reference: order.reference,
    restaurantId: order.restaurant_id,
    restaurant: restaurantResponseParse([order.__restaurant__])[0],
    supplier: supplierResponseParse(order.__supplier__),
    restaurantUserId: order.restaurant_user_id,
    status: order.status,
    supplierId: order.supplier_id,
    updatedAt: order.updated_at,
  }));
};

export const dbOrderLinesParse = (orderLines: OrderLines): DBOrderLines => {
  return (orderLines || []).map(ol => ({
    created_at: ol.createdAt,
    id: ol.id,
    order_id: ol.orderId,
    product_id: ol.productId,
    quantity: ol.quantity,
    updated_at: ol.updatedAt,
  }));
};
